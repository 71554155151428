<template lang="">
  <div>
    <Card>
      <template #header> Belgeler </template>
      <div class="row">
        <div
          class="d-flex align-items-center justify-content-between search-list-area"
        >
          <div
            class="d-flex align-items-center justify-content-between search-input mb-4"
          >
            <div class="col-md-2 col-12">
              <select v-model="list_length" class="form-control ml-2">
                <option value="5" selected>Seçiniz</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
            <input
              placeholder="Ara.."
              v-model="search"
              class="form-control mr-2"
            />
            <div class="d-flex justify-content-end">
              <b-button variant="success" @click="addModal = true"
                >Belge Ekle</b-button
              >
              <b-button
                variant="outline-primary"
                class="d-flex d-md-inline-block ml-2 w-md-auto w-100"
                v-b-toggle.sidebar-right
                >Filtrele
              </b-button>
            </div>
          </div>
        </div>

        <div class="custom-list">
          <List
            :header="header"
            :data="items"
            :pagination="pagination"
            :loading="loading"
            @updatePage="updatePage"
            moduleName="Belgeler Listesi"
            :moduleCreateEvent="true"
            :moduleSearch="search"
          >
            <template v-slot:item.dosya="{ item }">
              <a :href="'/image/' + item.dosya" target="_blank"
                >Dosyayı Görüntüle</a
              >
            </template>

            <template v-slot:item.action="{ item }" class="p-0">
              <div class="d-flex justify-content-start">
                <router-link
                  :to="`/mulkler/mulk-detay/${item.id}/`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <button class="btn" :href="href" @click="navigate">
                    <i class="flaticon2-arrow text-dark p-0"></i>
                  </button>
                </router-link>
                <router-link
                  :to="`properties/detail`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <button class="btn" :href="href" @click="navigate">
                    <i class="flaticon2-trash text-danger p-0"></i>
                  </button>
                </router-link>
              </div>
            </template>
          </List>
          <!-- Create and Actions Popups -->
        </div>
      </div>
    </Card>

    <div class="notes-sidebar top-full">
      <b-sidebar id="sidebar-right" right>
        <div
          class="card dveb-gray-card aside-gray-card tab-card"
          style="overflow-y: auto"
        >
          <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Filtre" active>
              <div class="form-group">
                <label for="user">Kullanıcı</label>
                <select name="" id="user" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Refik Selim Altıok</option>
                  <option value="">Rasim</option>
                  <option value="">Ahmet Berke Aydın</option>
                  <option value="">Mert Önal</option>
                </select>
              </div>

              <div class="form-group">
                <label for="start_date">Başlangıç Tarihi</label>
                <b-form-datepicker
                  id="start_date"
                  label-today="Bugün"
                  label-today-button="Bugünü Seç"
                  label-no-date-selected="Tarih Seçilmedi"
                  today-button
                  v-model="value"
                  class="mb-2"
                ></b-form-datepicker>
              </div>

              <div class="form-group">
                <label for="end_date">Bitiş Tarihi</label>
                <b-form-datepicker
                  id="end_date"
                  label-today="Bugün"
                  label-today-button="Bugünü Seç"
                  label-no-date-selected="Tarih Seçilmedi"
                  today-button
                  v-model="value"
                  class="mb-2"
                ></b-form-datepicker>
              </div>

              <div class="row">
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Temizle </b-button>
                </div>
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Uygula </b-button>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-sidebar>
    </div>
    <b-modal v-model="addModal" id="add-1" title="Yeni Özellik Ekle">
      <div class="form-group">
        <label for="deger">Belge Tipi</label>
        <select name="" id="" class="form-control">
          <option value="">Tapu</option>
          <option value="">Sigorta</option>
        </select>
      </div>
      <b-form-group class="mb-0">
            <label for="file"> Bilgisayar'dan Dosya seç </label>
            <b-form-file
              id="file"
              class="rounded-sm"
              placeholder="Dosya seçiniz"
              drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
              accept="image/*"
              multiple
            >
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </b-form-group>

      <template #modal-footer>
        <button class="btn btn-secondary" @click="addModal = false">Çık</button>
        <button class="btn btn-success" @click="addModal = false">
          Kaydet
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "customerList",

  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames: ["Hazırlık", "Satış", "Devir"],
      header: [
        { text: "#",sortable: true, value: "id", size: "70px" },
        { text: "Mülk", sortable: true, value: "mulk" },
        { text: "Belge Tipi", sortable: true, value: "tip" },
        { text: "Dosya Yolu", value: "dosya" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList: [],
      list_length: 5,
      openFullTextModal: false,
      addModal: false,
      file: "",
    };
  },
  components: {
    Moretext: () => import("@/components/global/Moretext"),
  },
  created() {
    this.search = this.$route.query.search;
    this.search = this.search.toLowerCase();
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.items = [
        {
          id: 1,
          mulk: "Karadeniz Apartmanı",
          tip: "Sigorta Belgesi",
          dosya: "image-150.png",
        },
      ];
      this.totalItems = this.items.length;

      this.pagination = {
        current_page: 1,
        total_pages: Math.ceil(this.items?.length / this.list_length),
        total_items: this.items.length,
        list_length: this.list_length,
      };
      this.loading = false;
      if (this.search) {
        const searchValue = this.search;
        const foundItems = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLowerCase().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        this.items = foundItems;
      }

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `property/log?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperation");
      let index = this.notes.indexOf(key);
      this.notes.splice(index, 1);
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function () {
      this.getList();
    },
    list_length: function () {
      this.getList();
    },
    $route: function () {
      this.search = this.$route.query.search;
      this.search = this.search.toLowerCase();
    },
  },
};
</script>
